const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.debmarine.forwoodsafety.com',
    USER_TOKEN_URL: 'https://yxfakfopb7.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://eov011a8bg.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.10.3',
    HOSTNAME: 'https://teams.staging.debmarine.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.staging.debmarine.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.staging.debmarine.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.staging.debmarine.forwoodsafety.com',
    WEBSOCKET: 'wss://2m8olo71sa.execute-api.us-west-2.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
